import React from "react"

const navigation = [
  { name: 'Martial Peak', href: '#', current: true, count: '5.0' },
  { name: 'Heavenly Inquisition Sword', href: '#', current: false, count: '5.0'  },
  { name: 'Solo Max-Level Newbie', href: '#', current: false, count: '4.3' },
  { name: 'Martial God Asura', href: '#', current: false, count: '4.3' },
  { name: 'Apotheosis', href: '#', current: false, count: '4.2'  },
  { name: 'Return of the SSS-Class Ranker', href: '#', current: false, count: '4.1'  },
  { name: 'Otherworldly Evil Monarch', href: '#', current: false, count: '4.1' },
  { name: 'My Dad Is Too Strong', href: '#', current: false, count: '4.0'  },
  { name: 'Overgeared', href: '#', current: false, count: '4.0' },
  { name: 'The Newbie is Too Strong', href: '#', current: false, count: '4.0' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Popular = () => {
  return (
    <nav className="space-y-1" aria-label="Sidebar">
      {navigation.map((item) => (
        <a
          key={item.name}
          href={item.href}
          className={classNames(
            item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'group flex items-center px-3 py-2 text-xs rounded-md align-middle'
          )}
          aria-current={item.current ? 'page' : undefined}
        >
          <span className="truncate">{item.name}</span>
          {item.count ? (
            <span
              className={classNames(
                item.current ? 'bg-white' : 'bg-gray-100 text-gray-600 group-hover:bg-gray-200',
                'ml-auto inline-block px-2 text-xs rounded-full'
              )}
            >
              <button type="button" className="inline-block rounded-full">
                <span class="sr-only">Add to favorites</span>
                  <svg xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 20 20" 
                        fill="currentColor" 
                        aria-hidden="true" 
                        class="text-yellow-300 hover:text-yellow-400 h-4 w-4">
                  <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z" clip-rule="evenodd"></path>
                  </svg>
              </button> {item.count}
            </span>
            
          ) : null}
        </a>
      ))}
    </nav>
  )
}

export default Popular
