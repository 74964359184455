import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo"
import Latest from "../components/latest"
import Rightcol from "../components/rightcol"

const Category = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Latest Manga" />
      <div className="flex-grow lg:flex border-t-4 border-green-500 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto ">
        <div className="min-w-0 flex-1 bg-white xl:flex">
         
          <div className="bg-transparent lg:min-w-0 lg:flex-1">
            <div className="border-b border-t border-gray-200 p-2 xl:border-t-0">
              <div className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                </svg>
                <h1 className="text-lg font-medium pl-2">LATEST MANGA</h1>
              </div>
            </div>

            <div className="pl-2 pr-2 pt-4 pb-4   xl:border-t-0  xl:pt-6" isHomePage>
              <div className="flex items-center">
              <Latest/>
              </div>
            </div>
          </div>
        </div>
        <Rightcol/>
      </div>
    </Layout>
  )
}

export default Category

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
