import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Rating from "../components/rating"
import ArrowRight from "../components/icon/arrow-right"

function truncate(str) {
  return str.length > 20 ? str.substring(0, 20) + "..." : str;
}

const Latest = () => {
  const data = useStaticQuery(
    graphql`
     query {
      allWpCategory(sort: { fields: name, order: DESC }) {
        nodes {
          name
          slug
          uri
          posts {
            nodes {
              title
              date(formatString: "MMMM DD, YYYY")
              uri
              featuredImage {
                node {
                  guid
                }
              }
            }
          }
        }
      }
     }
    `
  );

  const posts = data.allWpCategory.nodes

  return (
    <div className="flex flex-wrap">
      {posts.map(function(item, i) {
        const title = item.name
        const catPosts = posts[i].posts.nodes

        const hasPosts = catPosts.map(function(cat, k) {
          return cat.title
        })

        const el = Array.from(catPosts)[0];
        const featuredImage = el?.featuredImage?.node?.guid
        const postDate = el?.date

        const chapters = catPosts.slice(0, 3).map(cat => {
            return <div className="flex content-center"><ArrowRight/><a href={cat.uri} className='text-xs pl-1 block' style={{overflow: 'hidden', textOverflow: 'ellipsis'}}> {truncate(cat.title)} </a></div>
        }); 

        if (hasPosts.length) {
          return (
            <a
              key={item.name}
              href={item.uri}
              className='text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-3 py-2 text-xs rounded-md align-middle flex flex-wrap sm:w-1/2'
            >
              <div className="flex ">
                    <div className="mr-4 flex-shrink-0">
                      {featuredImage
                        ? <img
                            src={featuredImage}
                            className="h-full w-24 border border-gray-300 bg-white text-gray-300"
                            alt={title}
                          />
                        : 
                        <svg
                          className="h-full w-24 border border-gray-300 bg-white text-gray-300"
                          preserveAspectRatio="none"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 200 200"
                          aria-hidden="true"
                        >
                          <path vectorEffect="non-scaling-stroke" strokeWidth={1} d="M0 0l200 200M0 200L200 0" />
                        </svg>
                      }
                      
                    </div>
                    <div>
                    <h4 className="text-sm font-bold">{title}</h4>
                      <Rating/>
                      <p className="text-xs font-light">{postDate}</p>
                      <p className="items-center py-1 content-center">{chapters}</p>
                    </div>
              </div>
            </a>
          )
        } else { return(<></>)}
        
      })}
    </div>
  )
}

export default Latest
