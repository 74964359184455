import React from "react"

const navigation = [
  { name: 'All', href: '#', current: true, color: 'gray' },
  { name: 'Action', href: '#', current: false, color: 'red' },
  { name: 'Adventure', href: '#', current: false, color: 'yellow' },
  { name: 'Based on a Novel', href: '#', current: false, color: 'green' },
  { name: 'Comedy', href: '#', current: false, color: 'blue' },
  { name: 'Comic', href: '#', current: false, color: 'indigo' },
  { name: 'Completed Manga', href: '#', current: false, color: 'purple' },
  { name: 'Cooking', href: '#', current: false, color: 'pink' },
  { name: 'Drama', href: '#', current: false, color: 'gray' },
  { name: 'Ecchi', href: '#', current: false, color: 'red' },
  { name: 'Fantasy', href: '#', current: false, color: 'yellow' },
  { name: 'Harem', href: '#', current: false, color: 'green' },
  { name: 'Historical', href: '#', current: false, color: 'blue' },
  { name: 'Horror', href: '#', current: false, color: 'indigo' },
  { name: 'Isekai', href: '#', current: false, color: 'purple' },
  { name: 'Josei', href: '#', current: false, color: 'pink' },
  { name: 'Magic', href: '#', current: false, color: 'gray' },
  { name: 'Manhua', href: '#', current: false, color: 'red' },
  { name: 'Manhwa', href: '#', current: false, color: 'yellow' },
  { name: 'Martial Arts', href: '#', current: false, color: 'green' },
  { name: 'Mecha', href: '#', current: false, color: 'indigo' },
  { name: 'Medical', href: '#', current: false, color: 'purple' },
  { name: 'Mystery', href: '#', current: false, color: 'pink' },
  { name: 'Novel List', href: '#', current: false, color: 'gray' },
  { name: 'Psychological', href: '#', current: false, color: 'red' },
  { name: 'Reincarnation', href: '#', current: false, color: 'yellow' },
  { name: 'Romance', href: '#', current: false, color: 'green' },
  { name: 'School Life', href: '#', current: false, color: 'indigo' },
  { name: 'Sci-fi', href: '#', current: false, color: 'purple' },
  { name: 'Seinen', href: '#', current: false, color: 'pink' },
  { name: 'Shoujo', href: '#', current: false, color: 'gray' },
  { name: 'Shounen', href: '#', current: false, color: 'red' },
  { name: 'Slice of Life', href: '#', current: false, color: 'yellow' },
  { name: 'Sports', href: '#', current: false, color: 'green' },
  { name: 'Supernatural', href: '#', current: false, color: 'blue' },
  { name: 'Tragedy', href: '#', current: false, color: 'indigo' },
  { name: 'Webtoon', href: '#', current: false, color: 'purple' },
  { name: 'Zombie', href: '#', current: false, color: 'pink' },
]

const Genres = () => {
  return (
    <nav className="space-y-2 space-x-1 mb-6" aria-label="Sidebar">
      {navigation.map((item) => (
        
        <span className={`inline-flex items-center rounded-full  px-3 py-1.5 text-xs text-gray-800 bg-gray-200`}> {/* bg-${item.color}-400 */}
          <a
            key={item.name}
            href={item.href}
            aria-current={item.current ? 'page' : undefined}
          >
            {item.name}
          </a>
        </span>
      ))}
    </nav>
  )
}

export default Genres
